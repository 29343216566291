import { useAuth0 } from "@auth0/auth0-react";
import { useGetAssistantProcesses, useGetUserTodos } from "@hooks";
import { AssignmentOutlined, Cloud, Route } from "@mui/icons-material";
import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Flower } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Todos } from "src/components/NavBar/todos/Todos";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { AssistantProcessStatus } from "src/stores/models/assistant-process";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { ROUTES } from "../../config";

const DeviceNavbar = () => {
  const { pathname } = useLocation();
  const { orgId = "", deviceId = "" } = useParams();
  const { isAuthenticated } = useAuth0();
  const currentPath = useLocation().pathname;

  const { data: userTodos } = useGetUserTodos(
    orgId,
    deviceId,
    isAuthenticated && !!deviceId
  );

  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });

  const [assistantProcessUrl, setAssistantProcessUrl] = useState<string>(
    ROUTES.ASSISTANT
  );

  useEffect(() => {
    setAssistantProcessUrl(
      assistantProcesses?.[0]?.state?.currentPath || ROUTES.ASSISTANT
    );
  }, [assistantProcesses, orgId, deviceId]);

  const [anchorTodos, setAnchorTodos] = useState<HTMLElement | null>(null);
  const [areTodosOpen, setAreTodosOpen] = useState(false);

  const hasOutstandingActions =
    userTodos?.documentsTasks.toApprove.length !== 0 ||
    userTodos.documentsTasks.toAssignApprovers.length !== 0;

  const clickTodoIcon = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorTodos(event.currentTarget);
    setAreTodosOpen(!areTodosOpen);
  };

  const closeTodo = () => {
    setAnchorTodos(null);
    setAreTodosOpen(false);
  };

  return (
    <List
      key="device-list"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Device
        </ListSubheader>
      }
    >
      <ListItemButton
        key="roadmap"
        className="gap-4"
        component={Link}
        to={fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })}
        state={{ previous: pathname }}
      >
        <ListItemIcon className="min-w-fit">
          <Route />
        </ListItemIcon>
        <ListItemText primary={"Roadmap"} />
      </ListItemButton>

      <ListItemButton
        key="qms"
        className="gap-4"
        component={Link}
        to={fillRouteWithSlugs(ROUTES.QMS, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })}
        state={{ previous: pathname }}
      >
        <ListItemIcon className="min-w-fit">
          <Cloud />
        </ListItemIcon>
        <ListItemText primary={"eQMS"} />
      </ListItemButton>

      <ListItemButton
        key="Assistant"
        className="gap-4"
        component={Link}
        to={fillRouteWithSlugs(assistantProcessUrl as ROUTES, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })}
        state={{ previous: pathname }}
      >
        <ListItemIcon className="min-w-fit">
          <Flower />
        </ListItemIcon>
        <ListItemText primary={"Assistant"} />
      </ListItemButton>

      {!!deviceId && userTodos?.documentsTasks && (
        <>
          <ListItemButton
            className={`gap-4 ${currentPath?.includes("assistant-finished") ? "animate-jump animate-twice animate-ease-in-out animate-normal animate-fill-backwards" : ""}`}
            onClick={clickTodoIcon}
            color="inherit"
            aria-describedby="todos-menu"
          >
            <Badge
              className="ml-[1px]"
              color="error"
              invisible={!hasOutstandingActions}
              badgeContent={
                userTodos?.documentsTasks?.toApprove.length +
                userTodos?.documentsTasks?.toAssignApprovers.length
              }
            >
              <ListItemIcon className="min-w-fit">
                <AssignmentOutlined />
              </ListItemIcon>
            </Badge>
            <ListItemText primary={"Todos"} />
          </ListItemButton>
          <Todos
            todos={userTodos.documentsTasks}
            isOpen={areTodosOpen}
            closeTodo={closeTodo}
            anchorTodos={anchorTodos}
            hasOutstandingActions={hasOutstandingActions}
          />
        </>
      )}
    </List>
  );
};

export default DeviceNavbar;
